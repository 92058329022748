<template>


  <section class="container">
    <v-row class="d-flex justify-center align-center">
      <v-col cols="12" md="6" class="d-flex align-md-end justify-md-end align-center justify-center">
        <v-card max-width="350" min-height="200" class="card-hover d-flex justify-center align-center">
          <router-link class="d-flex justify-center align-center" :to="{ name: 'sobre_caritas_internacional' }">
            <img width="50%"
              class="card_image"
              src="/logos/logo-caritas-internationalis.png"
            />
          </router-link>
        </v-card>
      </v-col>

      <v-col cols="12" md="6" class="d-flex align-md-start justify-md-start align-center justify-center">
        <v-card max-width="350" min-height="200" class="card-hover d-flex justify-center align-center">
          <router-link class="d-flex justify-center align-center" :to="{ name: 'sobre_caritas_al' }">
            <img width="50%"
              class="card_image"
              src="/logos/logo_america_latina.png"
            />
          </router-link>
        </v-card>
      </v-col>
      
    </v-row>

    <v-row class="d-flex justify-center align-center">
      <v-col cols="12" md="6" class="d-flex align-md-end justify-md-end align-center justify-center">
        <v-card min-width="350" min-height="200" class="card-hover d-flex justify-center align-center">
          <router-link class="d-flex justify-center align-center" :to="{ name: 'sobre_caritas_brasileira' }">
            <img width="50%"
              class="card_image"
              src="/logos/logo_brasileira.png"
            />
          </router-link>
        </v-card>
      </v-col>

      <v-col cols="12" md="6" class="d-flex align-md-start justify-md-start align-center justify-center">
        <v-card max-width="350" min-height="200" class="card-hover d-flex justify-center align-center">
          <router-link class="d-flex justify-center align-center" :to="{ name: 'sobre_caritas_regional' }">
            <img width="50%"
              class="card_image"
              src="/logos/logo-caritas-main-preto.png"
            />
          </router-link>
        </v-card>
      </v-col>
      
    </v-row>
  </section>

</template>

<script>
export default {};
</script>

<style scoped>

.card-hover:hover {
  border: 4px solid #c92f05;
}

img {
  text-align: center;
}

.display--relative {
  position: relative;
}
.display--relative img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
